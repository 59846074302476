import React, { useEffect } from 'react';
import Layout from "../components/layout"
import '../styles/LiveDemo.css';
import { graphql } from "gatsby";
import { DefaultGtag } from '../utils/misc'
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { registerUrl } from '../utils/misc'


const contentStyles = {
    padding: "3rem 1rem 0 1rem",
    maxWidth: "55rem",
    margin: "0 auto",
    textAlign: "center",
}

const h1Style = {
    maxWidth: "55rem",
    padding: "1rem",
    textAlign: "center",
    margin: "0 auto 4rem auto"
}

const thumbnailStyle = {
    display: "inline-block",
    margin: "1rem",
    marginBottom: "4rem"
}

const thumbnailImgStyle = {
    width: "8rem",
    height: "6rem"
}

const menus = [
    { "text": "The Secret Garden", "logo": "https://public.takeaseat.io/restaurants/green-garden-vege-cafe-demo/bm greengarden logo icon.png", "link": `https://green-garden-vege-cafe-demo.tas.ink/menu?context=landing-page` },
    { "text": "Sushi Koi", "logo": "https://public.takeaseat.io/restaurants/sushi-koi-demo/Sushi_Koi_CS6-09.png", "link": `https://sushi-koi-demo.tas.ink/menu?context=landing-page` },
    { "text": "Collectors Café", "logo": "https://public.takeaseat.io/restaurants/collectors-cafe-demo/16A5B285-9D42-41E9-8ABB-6EFEA9200507.jpeg", "link": `https://collectors-cafe-demo.tas.ink/menu?context=landing-page` },
    { "text": "Richiamo", "logo": "https://public.takeaseat.io/restaurants/richiamo-demo/Richiamo transparent.png", "link": `https://richiamo-demo.tas.ink/menu?context=landing-page` },
    { "text": "Central Park", "logo": "https://public.takeaseat.io/restaurants/centralpark-demo/logocentralzameni.png", "link": `https://centralpark-demo.tas.ink/menu?context=landing-page` },
    { "text": "Linger Trolley", "logo": "https://public.takeaseat.io/restaurants/linger-trolley-demo/da.jpg", "link": `https://linger-trolley-demo.tas.ink/menu?context=landing-page` },
    { "text": "Holy Basil", "logo": "https://public.takeaseat.io/restaurants/holybasileate-demo/Holy Basil (2).png", "link": `https://holybasileate-demo.tas.ink/menu?context=landing-page` },
    { "text": "Chai Sutta Bar", "logo": "https://public.takeaseat.io/restaurants/chai-sutta-demo/71097DC6-A230-4ED9-A2D7-BE93DC79CD27.jpeg", "link": `https://chai-sutta-demo.tas.ink/menu?context=landing-page` },
    { "text": "Bubble Pup", "logo": "https://public.takeaseat.io/restaurants/bubble-pop-demo/2E7E776A-C7D5-4BA0-A58C-5607753410D0.jpeg", "link": `https://bubble-pop-demo.tas.ink/menu?context=landing-page` },
    // { "text": "Super tiuna", "logo": "https://public.takeaseat.io/restaurants/supertiuna-demo/super tiuna-10 (1).png", "link": `https://supertiuna-demo.tas.ink/menu?context=landing-page` },
    { "text": "Beylerbeyi Sarayi", "logo": "https://public.takeaseat.io/restaurants/beylerbeyisarayi-demo/official logo.jpg", "link": `https://beylerbeyisarayi-demo.tas.ink/menu?context=landing-page` },
    { "text": "ICEBAR", "logo": "https://public.takeaseat.io/logos/webp/bd40b74c-3094-4c0a-aca1-c0a59f9dc836.webp", "link": `https://icebarshabushabu-demo.tas.ink/menu?context=landing-page` },
    { "text": "Shell Beans", "logo": "https://public.takeaseat.io/logos/webp/d0c65ab0-f096-44e5-9fa6-f5fcedf6fac2.webp", "link": `https://shell-beans-demo.tas.ink/menu?context=landing-page` },
    { "text": "Pantheon Coffees", "logo": "https://public.takeaseat.io/logos/webp/01dc4be9-ac52-4440-b9f5-03787b86cfa8.webp", "link": `https://pantheon-demo.tas.ink/menu?context=landing-page` },
    { "text": "ADDICT BURGER", "logo": "https://public.takeaseat.io/logos/webp/69467bd0-c97e-4b19-bf03-4f65f0bfbefb.webp", "link": `https://addict-burger-demo.tas.ink/menu?context=landing-page` },
]

function MenuThumbnail({ logoSrc, link, text }) {
    return <div className="menu-thumbnail" style={{
        ...thumbnailStyle,
        backgroundColor: (link == `https://public.takeaseat.io/supertiuna/menu?context=landing-page`) ? "rgb(220, 220, 220)" : ""
    }}>
        <a href={link} target="blank_">
            <div className="img" style={{
                ...thumbnailImgStyle,
                background: "url('" + encodeURI(logoSrc) + "') center center / contain no-repeat"
            }}></div>
            <span style={{ whiteSpace: "nowrap", fontFamily: "Lora" }}>{text}</span>
        </a>
    </div>
}

const PopularPage = () => {
    const { t } = useTranslation();
    const [rUrl, setRUrl] = React.useState('https://admin.takeaseat.io/register?solution=digital-menu')

    useEffect(() => {
        setTimeout(function () {
            // need delay, otherwise not working on firefox...
            window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
        }, 100);
        setRUrl(registerUrl())
    }, [])

    return <>
        <Layout>
            <div className="App popular-menus">
                <div style={contentStyles}>
                    <h1 style={h1Style}>{t('popular-menus.title')}</h1>
                    {menus.map((menu, i) => {
                        return <MenuThumbnail
                            key={`popular-menu-${i}`}
                            logoSrc={menu.logo} link={menu.link} text={menu.text} />
                    })}
                </div>
                <div className="account-creation" style={{ margin: "auto", justifyContent: "center", paddingBottom: "3rem" }}>
                    <a href={rUrl} rel="noreferrer" className="tas-button wide subtitle"
                        onClick={() => {
                            window.gtag_report_conversion(rUrl + "&tier=standard&source=landing.valueproposition")
                            window.gtag('event', 'click_on_get_started_popular_menus');
                        }}>
                        <div>{t(`navigation.free-sign-up`)}</div>
                    </a>
                </div>
            </div>
        </Layout>
    </>
}


export default PopularPage;

export const Head = () => <>
    <DefaultGtag />
    {/* <link rel="canonical" href="https://takeaseat.io/popular-menus/" /> */}
    <link rel="alternate" hreflang="x-default" href="https://takeaseat.io/popular-menus/" />
    <link rel="alternate" hreflang="fr" href="https://takeaseat.io/fr/popular-menus/" />
    <link rel="alternate" hreflang="de" href="https://takeaseat.io/de/popular-menus/" />
    <link rel="alternate" hreflang="es" href="https://takeaseat.io/es/popular-menus/" />
    <title>Popular menus on Take a Seat</title>
    <meta name="description" content="Popular menus on Take a Seat." />
</>

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["cookies","index", "navigation"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;